import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Router from './routes';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/free-mode';
import { useEffect, useRef, useState } from 'react';
import PushNotifications from 'component/pushNotifications';
import { getAccessVal, isObjectEmpty, signOut } from 'utils/constantFun';
import ScrollToTop from 'component/scrollToTop';
import * as Sentry from '@sentry/react';
import { userLogout } from 'store/slice/auth';
import { useDispatch, useSelector } from 'react-redux';
import { siteSetting } from 'store/slice/profile';
import { SITE_SETTING } from 'utils/appConstant';

function App() {
  let toastId = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(true); // Assuming the user is initially logged in
  const timeoutRef = useRef(null); // Using ref instead of state for timeout
  const dispatch = useDispatch();
  const { siteSettingObject } = useSelector((state) => state.profile);
  // Sentry set up
  Sentry.init({
    // dsn: 'https://35621393b6f6d7e075311231b252f34a@o4508439481286656.ingest.us.sentry.io/4508442678263808',
    dsn: false,
    integrations: [Sentry.browserTracingIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  useEffect(() => {
    if (isObjectEmpty(SITE_SETTING)) {
      dispatch(siteSetting());
    }
  }, []);
  // Function to log out the user
  const logout = () => {
    if (getAccessVal() && process.env.REACT_APP_MODE != 'qa') {
      setIsLoggedIn(false);
      // Redirect to login page after logout
      dispatch(userLogout(signOut));
    }
  };
  // Reset the inactivity timer whenever there's activity
  const resetTimer = () => {
    // Clear the existing timeout if it exists
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    // Set a new timeout
    timeoutRef.current = setTimeout(
      logout,
      SITE_SETTING
        ? ((SITE_SETTING?.DEFAULT_APP_INACTIVE_TIME_SECONDS * 1) / 60) * 60 * 1000
        : ((siteSettingObject?.DEFAULT_APP_INACTIVE_TIME_SECONDS * 1) / 60) * 60 * 1000
    );
  };

  // Attach event listeners for user activity
  useEffect(() => {
    const events = ['mousemove', 'keydown', 'scroll', 'click'];
    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Initial inactivity timer
    resetTimer();

    // Cleanup listeners on component unmount
    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []); // Empty dependency array ensures this effect only runs once (on mount)

  // If the user is logged out, we don’t need to render the rest of the app
  if (!isLoggedIn && process.env.REACT_APP_MODE != 'qa') {
    return <div style={{ height: '100vh', background: 'black' }}></div>;
  }

  return (
    <>
      <PushNotifications />
      <HelmetProvider>
        <BrowserRouter>
          <ScrollToTop />
          <Router />
        </BrowserRouter>
      </HelmetProvider>

      <ToastContainer
        ref={toastId}
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default App;
